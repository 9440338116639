import React from "react";
import HeaderImage from './Header.svg'
import Logos from "./Frame.svg"
import { Typography } from "@material-ui/core";
import { Button, CircularProgress, makeStyles } from "@material-ui/core";
import { fontFamily, lineHeight } from "@mui/system";
import { Grid } from "@mui/material";


const useStyles = makeStyles((theme) => ({
    texts: {
        fontWeight: '400',
        fontSize: '25px',
        lineHeight: '37px',
        marginTop: '20px'
    },
    headline: {
        fontWeight: '600',
        fontSize: '25px',
        lineHeight: '37px',
        marginTop: '15px',
        marginBottom: '15px'
    },
    footer: {
        fontWeight: '600',
        fontSize: '22px',
        lineHeight: '20px',
        marginTop: '5px',
        color: '#098AFD'
    }


}));


export default function ThankYou() {
    const classes = useStyles();

    return (
        <div style={{ padding: '20px' }}>
            {/* <img src={HeaderImage}>
            </img> */}
            <div>
                <Grid container>
                    <Grid item sx={3}>
                        <img src={Logos} style={{ height: '100px', width: '100px' }}>
                        </img>
                    </Grid>
                    <Grid item sx={9}>
                        <div style={{ display: 'flex' }}>
                          
                            <Typography style={{ fontWeight: 700, fontSize: '30px', padding: '28px' }}>
                                <span style={{ color: "#098AFD" }}>Thank You</span> for contacting Medical Circles
                            </Typography>
                        </div>
                    </Grid>
                </Grid>
            </div>
            <div style={{ width: '90%', marginTop: '50px' }}>
                <Typography className={classes.texts}>
                    Dear {window.localStorage.getItem('name')},
                </Typography>
                <Typography className={classes.texts}>

                    Thank you for contacting Medical Circles.
                    {/* We are excited about the potential of our medical collaboration software to enhance your organization’s productivity
                    and improve patient outcomes. */}
                </Typography>
                {/* <Typography className={classes.headline}>
                    What to Expect?
                </Typography>
                <Typography className={classes.texts}>
                    We would love to schedule a personalized demo to showcase how
                    Medical Circles can be tailored to meet your specific needs.
                    Our sales team will reach out in the next 2 weeks via <span style={{ color: '#098AFD' }}>sales@medicalcircles.com</span> to arrange a
                    session at your convenience.
                </Typography> */}
                <Typography className={classes.headline}>
                    Have any Questions?
                </Typography>
                <Typography className={classes.texts}>
                    If you have any questions or need further information,
                    please do not hesitate to ask.
                    We are here to help you understand how Medical Circles can benefit your practice or organization.
                    Just reply to this email with your questions and our team will get back to you shortly.


                </Typography>
                <Typography className={classes.texts}>
                    We look forward to continuing the conversation and exploring how we can support your goals.

                </Typography>
            </div>
            <div>
                <Typography className={classes.texts} style={{ marginTop: "20px" }}>
                    Best Regards
                </Typography>
                <Typography className={classes.footer} >
                    Dr, Zyad Almohya
                </Typography>
                <Typography className={classes.footer}  >
                    Chief Executive Officer, Medical Circles
                </Typography>
                <Typography className={classes.footer}  >
                    +966 544778116
                </Typography>
            </div>

        </div>

    )
}