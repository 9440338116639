import AppBar from "@mui/material/AppBar";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../providers/AuthProvider";
import NotificationImage from "./../../assets/Notification.png";
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import { isMobile } from "react-device-detect";
import MainDrawer2 from "../../pages/MainDrawer/ResponsiveDrawer";
import { Icon } from "@material-ui/core";


const pages = ["Products", "Pricing", "Blog"];
const settings = ["My Profile", "Logout"];

function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const auth = useAuth();
  const navigate = useNavigate();

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <>
      {
        isMobile ? (
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <MainDrawer2 />
            <Tooltip>

              {/* <IconButton onClick={handleOpenUserMenu}>
                <NotificationsNoneOutlinedIcon style={{color:'#246AF1'}} />
              </IconButton> */}
              <IconButton onClick={handleOpenUserMenu}>
                <Avatar
                  sx={{ height: "52px", width: "52px" }}
                  alt={JSON.parse(window.localStorage.getItem("user")).first_name}
                  src="/static/images/avatar/2.jpg"
                />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >


              {settings.map((setting) => (
                <MenuItem
                  key={setting}
                  onClick={() => {
                    if (setting == "Logout") {
                      auth.logout();
                    } else {
                      navigate("/profile");
                    }
                  }}
                >
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </div>
        ) : (
          <AppBar
            position="static"
            sx={{ background: "transparent", boxShadow: "none" }}
          >
            {/* <Container> */}
            <Toolbar disableGutters>

              <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
                <div>
                  <Typography variant="h1" sx={{ fontWeight: 400 }}>
                    Hi, {JSON.parse(window.localStorage.getItem("user")).first_name}
                  </Typography>
                  {/* <Typography variant="h2" sx={{ fontWeight: 400 }}>
                Let's finish your task today!{" "}
              </Typography> */}
                </div>
              </Box>

              <Box sx={{ flexGrow: 0 }}>
                <Tooltip>
{/* 
                  <IconButton onClick={handleOpenUserMenu}>
                  <NotificationsNoneOutlinedIcon style={{color:'#246AF1'}} />
                  </IconButton> */}
                  <IconButton onClick={handleOpenUserMenu}>
                    <Avatar
                      sx={{ height: "52px", width: "52px", backgroundColor:'#EA0234' }}
                      alt={JSON.parse(window.localStorage.getItem("user")).first_name}
                      src="/static/images/avatar/2.jpg"
                    />
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: "45px" }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >


                  {settings.map((setting) => (
                    <MenuItem
                      key={setting}
                      onClick={() => {
                        if (setting == "Logout") {
                          auth.logout();
                        } else {
                          navigate("/profile");
                        }
                      }}
                    >
                      <Typography textAlign="center">{setting}</Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
            </Toolbar>
            {/* </Container> */}
          </AppBar>
        )
      }



    </>

  );
}
export default ResponsiveAppBar;
