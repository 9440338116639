import React from "react";
import "./styles.css";
import TwitterImage from "./twitter.svg";
import LinkedinImage from "./linkedin.svg";
import WebsiteImage from "./website.svg";
import InboxImage from "./Inbox.svg";
import MedicalImage from "./MedicalCirclesLogo.svg";
import { useParams } from "react-router-dom"

function ApprovedMail() {
  let { name } = useParams()
  return (
    <div class="body" style={{ fontFamily: "Outfit" }}>
      <div class="container">
        <div class="header">
          <h2
            class="emailOwner"
            style={{ marginBottom: "20px", fontFamily: "Outfit" }}
          >
            Hi {name},
          </h2>
          <h3
            class="welcomeText"
            style={{ marginBottom: "20px", fontFamily: "Outfit" }}
          >
            Welcome to Medical Circles!
          </h3>
        </div>
        <center>
          <img class="heroimg" src={InboxImage} alt="Inbox Img" />
        </center>
        <div class="content">
          <p class="darkParagraph" style={{ fontFamily: "Outfit" }}>
            Congratulations! Your email has been successfully verified.
          </p>
          <p>
            <a href="#">
              <button class="actionBtn1" style={{ fontFamily: "Outfit" }}>
                APPROVED
              </button>
            </a>
          </p>
          {/* <p class="lightParagraph" style={{ fontFamily: "Outfit" }}>
            By confirming your email address, you'll be able to recover your
            account via email if you ever lose access to it or if your phone
            number changes in the future.
          </p> */}
          <p
            class="TeamTextbold"
            style={{ marginBottom: "20px", fontFamily: "Outfit" }}
          >
            From Team
          </p>
          <center>
            <img
              class="MedicalCirclesLogo"
              src={MedicalImage}
              style={{ marginBottom: "20px" }}
              alt="MedicalCirclesLogo"
            />
          </center>
          <p
            class="TeamTextbold"
            style={{ marginBottom: "20px", fontFamily: "Outfit" }}
          >
            Need Help?{" "}
          </p>

          <p
            class="supportText"
            style={{ marginBottom: "20px", fontFamily: "Outfit" }}
          >
            If you have any questions or concerns, we’re here to help. Contact
            us on{" "}
            <a href="mailto:customersupport@medicalcircles.com">
              customersupport@medicalcircles.com
            </a>
          </p>

          <div class="socialMediaGroup">
            <a
              style={{ fontFamily: "Outfit" }}
              class="socialMediaLinks"
              href="#"
            >
              <img src={TwitterImage} alt="twitter" />
            </a>
            <a
              style={{ fontFamily: "Outfit" }}
              class="socialMediaLinks"
              href="#"
            >
              <img src={LinkedinImage} alt="linkedin" />
            </a>
            <a
              style={{ fontFamily: "Outfit" }}
              class="socialMediaLinks"
              href="#"
            >
              <img src={WebsiteImage} alt="website" />
            </a>
          </div>
        </div>

        <div class="footer">
          <p class="footerAddress" style={{ fontFamily: "Outfit" }}>
            Riyadh, Saudi Arabia
          </p>
        </div>
      </div>
    </div>
  );
}

export default ApprovedMail;
