import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Grid, Paper, TableRow } from "@mui/material";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import moment from "moment";
import { SnackbarProvider, enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";
import useGeneralStyle from "../../assets/css/general";
import Datatables from "../../components/Datatables";
import { apiClient } from "../../config/apiClient";
import { STATUS_COLOR } from "../../config/constants";
import useCallbackStatus from "../../hooks/useCallbackStatus";
import useCancelRequest from "../../hooks/useCancelToken";


function createData(requested_by, profession, speciality, subSpeciality, email, phone, time, status) {
    return { requested_by, profession, speciality, subSpeciality, email, phone, time, status };
}

// const rows = [
//     createData('Ahmed Hanafy', "Physician", "Trauma", "Spin", "Ahmedhanafy925@gmail.com", "+966556789", "9th Jul 2024 09:37 PM", "Approved"),
//     createData('Noha Alawdy', "Medicine Resident", "Medicine", "Diet", "noha@gmail.com", "+966584296", "11th Sept 2024 10:40 PM", "Pending"),

// ];
// const card = (
//     <React.Fragment>
//         <div style={{ display: 'flex', justifyContent: 'space-between', padding: '20px', borderRadius: '' }}>
//             <Typography style={{ fontWeight: '700', fontSize: '24px' }}>
//                 User Requests
//             </Typography>

//             <Card
//                 onClick={() => {
//                     window.location.href = "/enquiries/user_requests"

//                     // navigate("/enquiries/user_requests")
//                 }}
//                 sx={{
//                     width: "100%",
//                     cursor: 'pointer',
//                     minWidth: "200px",
//                     height: "160px",
//                     backgroundColor: "white",
//                     borderRadius: "10px",
//                     boxShadow: 0,
//                 }}
//             >
//                 <CardContent>
//                     <Typography sx={{ fontSize: "16px" }} gutterBottom>
//                         Total Pending Requests{" "}
//                     </Typography>

//                     <Typography
//                         sx={{ fontSize: "45px" }}
//                         color="#246AF1"
//                         style={{ display: "flex", justifyContent: "center" }}
//                     >
//                         {data[0].total_pending_requests}
//                     </Typography>
//                 </CardContent>
//                 {/* <CardActions> */}
//                 <Box sx={{ flexGrow: 1, padding: "10px" }}>
//                     <Grid container spacing={2}>
//                         <Grid item xs={6}>
//                             {/* <div style={{ width: "68px", height: "68px" }}>
//                     <CircularProgressbar
//                       value={66}
//                       text={`${66}%`}
//                       strokeWidth={5}
//                       styles={buildStyles({
//                         textColor: "white",
//                         pathColor: "#546FFF",
//                         trailColor: "#1a1e38",
//                       })}
//                     />{" "}
//                   </div> */}
//                         </Grid>
//                         {/* <Grid item xs={6} sx={{ marginTop: "5%" }}>
//                   <div>
//                     <Typography sx={{ fontSize: "20px" }} color="white">
//                       100
//                     </Typography>
//                     <Typography sx={{ fontSize: "14px" }} color="#8E92BC">
//                       Task
//                     </Typography>
//                   </div>
//                 </Grid> */}
//                     </Grid>
//                 </Box>
//                 {/* </CardActions> */}
//             </Card>

//             <Button variant='outlined'
//                 endIcon={
//                     <KeyboardArrowDownIcon />
//                 }
//                 disableElevation style={{ backgroundColor: '#FCFDFD', borderColor: '#D4D4D4', textTransform: 'none', color: '#D4D4D4' }}>
//                 October
//             </Button>
//         </div>
//         <div style={{ padding: '20px' }}>
//             <TableContainer component={Paper} elevation={0} style={{ borderRadius: '10px' }}>
//                 <Table sx={{ minWidth: 650 }}>
//                     <TableHead style={{ backgroundColor: '#F1F4F9' }} borderRadius="10px">
//                         <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}                        >
//                             <TableCell>
//                                 <Typography style={{ fontSize: '14px', fontWeight: '500', color: '#202224' }}>
//                                     Requested By
//                                 </Typography>
//                             </TableCell>
//                             <TableCell >
//                                 <Typography style={{ fontSize: '14px', fontWeight: '500', color: '#202224' }}>
//                                     Profession
//                                 </Typography>
//                             </TableCell>
//                             <TableCell >
//                                 <Typography style={{ fontSize: '14px', fontWeight: '500', color: '#202224' }}>
//                                     Speciality
//                                 </Typography>
//                             </TableCell>
//                             <TableCell >
//                                 <Typography style={{ fontSize: '14px', fontWeight: '500', color: '#202224' }}>
//                                     Sub-Speciality
//                                 </Typography>
//                             </TableCell>
//                             <TableCell >
//                                 <Typography style={{ fontSize: '14px', fontWeight: '500', color: '#202224' }}>
//                                     Email
//                                 </Typography>
//                             </TableCell>
//                             <TableCell >
//                                 <Typography style={{ fontSize: '14px', fontWeight: '500', color: '#202224' }}>
//                                     Phone
//                                 </Typography>
//                             </TableCell>
//                             <TableCell >
//                                 <Typography style={{ fontSize: '14px', fontWeight: '500', color: '#202224' }}>
//                                     Date-Time
//                                 </Typography>
//                             </TableCell>
//                             <TableCell >
//                                 <Typography style={{ fontSize: '14px', fontWeight: '500', color: '#202224' }}>
//                                     Status
//                                 </Typography>
//                             </TableCell>
//                         </TableRow>
//                     </TableHead>
//                     <TableBody>
//                         {rows.map((row) => (
//                             <TableRow
//                                 key={row.name}
//                                 sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
//                             >
//                                 <TableCell>
//                                     <Typography
//                                         style={{
//                                             fontSize: '14px',
//                                             fontWeight: '400',
//                                             color: '#202224'
//                                         }}>
//                                         {row.requested_by}
//                                     </Typography>
//                                 </TableCell>
//                                 <TableCell>
//                                     <Typography
//                                         style={{
//                                             fontSize: '14px',
//                                             fontWeight: '400',
//                                             color: '#202224'
//                                         }}>
//                                         {row.profession}
//                                     </Typography>
//                                 </TableCell>
//                                 <TableCell>
//                                     <Typography
//                                         style={{
//                                             fontSize: '14px',
//                                             fontWeight: '400',
//                                             color: '#202224'
//                                         }}>
//                                         {row.speciality}
//                                     </Typography>
//                                 </TableCell>
//                                 <TableCell>
//                                     <Typography
//                                         style={{
//                                             fontSize: '14px',
//                                             fontWeight: '400',
//                                             color: '#202224'
//                                         }}>
//                                         {row.subSpeciality}
//                                     </Typography>
//                                 </TableCell>
//                                 <TableCell>
//                                     <Typography
//                                         style={{
//                                             fontSize: '14px',
//                                             fontWeight: '400',
//                                             color: '#202224'
//                                         }}>
//                                         {row.email}
//                                     </Typography>
//                                 </TableCell>
//                                 <TableCell>
//                                     <Typography
//                                         style={{
//                                             fontSize: '14px',
//                                             fontWeight: '400',
//                                             color: '#202224'
//                                         }}>
//                                         {row.phone}
//                                     </Typography>
//                                 </TableCell>
//                                 <TableCell>
//                                     <Typography
//                                         style={{
//                                             fontSize: '14px',
//                                             fontWeight: '400',
//                                             color: '#202224'
//                                         }}>
//                                         {row.time}
//                                     </Typography>
//                                 </TableCell>
//                                 <TableCell>
//                                     <Button
//                                         variant="outlined"
//                                         disableRipple
//                                         style={{
//                                             borderRadius: "10px",
//                                             border: row.status === "Approved" ? "1px solid #00C3B8" : "1px solid #F29006",
//                                             height: '30px',
//                                             backgroundColor: row.status === "Approved" ? '#00C3B8' : "#F29006", height: '30px',
//                                             height: "30px",
//                                             margin: "5px",
//                                             color: "white",
//                                             cursor: "default",
//                                             textTransform: "none",
//                                         }}
//                                     >
//                                         {row.status}
//                                     </Button>
//                                     {/* <div style={{
//                                         backgroundColor: row.status === "Approved" ? '#00C3B8' : "#F29006", height: '30px',
//                                         borderRadius: '13px'
//                                     }}>
//                                         <Typography
//                                             style={{
//                                                 fontSize: '14px',
//                                                 fontWeight: '400',
//                                                 color: "white",
//                                                 padding: '5px',
//                                                 marginLeft: '5px'
//                                             }}>
//                                             {row.status}
//                                         </Typography>
//                                     </div> */}
//                                 </TableCell>
//                             </TableRow>
//                         ))}
//                     </TableBody>
//                 </Table>
//             </TableContainer>
//         </div>
//     </React.Fragment>
// );

export default function OutlinedCard({ data }) {

    const getTicketApiStatus = useCallbackStatus();
    const apiSource = useCancelRequest();
    const [adminsList, setAdminList] = useState([]);
    const [rows, setRows] = useState([])
    let getAllTickets = async (token, refresh, updateStatus) => {
        var apiBody = {
            action: "list",
            pagination_required: true,
            next_token: token,
            label_type: "specialty",

        };
        if (!token) {
            delete apiBody.next_token;
        }
        console.log("My body");
        console.log(apiBody);
        await getTicketApiStatus.run(
            apiClient("POST", "admin", "managerequestedlabel", {
                body: { ...apiBody },
                shouldUseDefaultToken: false,
                cancelToken: apiSource.token,
                enableLogging: true,
            })
                .then((result) => {
                    const {
                        content: { data, has_more, next_token },
                    } = result;

                    console.log(result);
                    setRows(data)
                })
                .catch((error) => {
                    enqueueSnackbar(error.message, {
                        variant: "error",
                    });
                })
        );
    };

    useEffect(() => {
        getAllTickets(null, true);
    }, []);



    return (
        // <Grid item xs={12} style={{ paddingTop: "20px" }}>
        <div>
            <Paper elevation={0} style={{
                width: "100%",
                overflowX: "auto",
                backgroundColor: 'white',
                borderRadius: '12px'
            }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', padding: '20px', borderRadius: '' }}>
                    <Typography style={{ fontWeight: '700', fontSize: '24px' }}>
                        User Requests
                    </Typography>
{/* 
                    <Button variant='outlined'
                        endIcon={
                            <KeyboardArrowDownIcon />
                        }
                        disableElevation style={{ backgroundColor: '#FCFDFD', borderColor: '#D4D4D4', textTransform: 'none', color: '#D4D4D4' }}>
                        October
                    </Button> */}
                </div>
                <div style={{ padding: '20px' }}>
                    <Grid container spacing={2}>
                        <Grid item sx={6}>

                            <Card
                                onClick={() => {
                                    window.location.href = "/enquiries/user_requests"
                                    // navigate("/enquiries/user_requests")
                                }}
                                sx={{
                                    width: "50%",
                                    cursor: 'pointer',
                                    minWidth: "200px",
                                    height: "150px",
                                    backgroundColor: "#F1F4F9",
                                    borderRadius: "10px",
                                    boxShadow: 0,
                                }}
                            >
                                <CardContent>
                                    <div style={{
                                        display: "flex", justifyContent: 'space-between'
                                    }}>
                                        <Typography sx={{ fontSize: "16px" }} gutterBottom>
                                            Total Pending Requests{" "}
                                        </Typography>
                                        <Typography style={{ fontSize: '16px', color: '#246AF1', cursor: 'pointer' }}>
                                            See more
                                        </Typography>
                                    </div>

                                    <Typography
                                        sx={{ fontSize: "45px" }}
                                        color="#246AF1"
                                        style={{ display: "flex", justifyContent: "center" }}
                                    >
                                        {data[0].total_pending_requests}
                                    </Typography>
                                </CardContent>
                                {/* <CardActions> */}
                                <Box sx={{ flexGrow: 1, padding: "10px" }}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            {/* <div style={{ width: "68px", height: "68px" }}>
                    <CircularProgressbar
                      value={66}
                      text={`${66}%`}
                      strokeWidth={5}
                      styles={buildStyles({
                        textColor: "white",
                        pathColor: "#546FFF",
                        trailColor: "#1a1e38",
                      })}
                    />{" "}
                  </div> */}
                                        </Grid>
                                        {/* <Grid item xs={6} sx={{ marginTop: "5%" }}>
                  <div>
                    <Typography sx={{ fontSize: "20px" }} color="white">
                      100
                    </Typography>
                    <Typography sx={{ fontSize: "14px" }} color="#8E92BC">
                      Task
                    </Typography>
                  </div>
                </Grid> */}
                                    </Grid>
                                </Box>
                                {/* </CardActions> */}
                            </Card>
                        </Grid>
                    </Grid>

                </div>
                <div style={{ padding: '20px' }}>
                    <TableContainer component={Paper} elevation={0} style={{ borderRadius: '10px' }}>
                        <Table sx={{ minWidth: 650 }}>
                            <TableHead style={{ backgroundColor: '#F1F4F9' }} borderRadius="10px">
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}                        >
                                    <TableCell>
                                        <Typography style={{ fontSize: '14px', fontWeight: '500', color: '#202224' }}>
                                            Created At
                                        </Typography>
                                    </TableCell>
                                    <TableCell >
                                        <Typography style={{ fontSize: '14px', fontWeight: '500', color: '#202224' }}>
                                            Profession name
                                        </Typography>
                                    </TableCell>
                                    <TableCell >
                                        <Typography style={{ fontSize: '14px', fontWeight: '500', color: '#202224' }}>
                                            Speciality
                                        </Typography>
                                    </TableCell>
                                    <TableCell >
                                        <Typography style={{ fontSize: '14px', fontWeight: '500', color: '#202224' }}>
                                            Requested By
                                        </Typography>
                                    </TableCell>
                                    <TableCell >
                                        <Typography style={{ fontSize: '14px', fontWeight: '500', color: '#202224' }}>
                                            Email
                                        </Typography>
                                    </TableCell>
                                    <TableCell >
                                        <Typography style={{ fontSize: '14px', fontWeight: '500', color: '#202224' }}>
                                            Phone
                                        </Typography>
                                    </TableCell>
                                    <TableCell >
                                        <Typography style={{ fontSize: '14px', fontWeight: '500', color: '#202224' }}>
                                            Status
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map((row, index) => (
                                    index <= 2 && (
                                        <TableRow
                                            key={row.name}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell>
                                                <Typography
                                                    style={{
                                                        fontSize: '14px',
                                                        fontWeight: '400',
                                                        color: '#202224'
                                                    }}>
                                                    {moment
                                                        .unix(row.created_at)
                                                        .format("Do MMM YYYY, hh:mm A")}
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography
                                                    style={{
                                                        fontSize: '14px',
                                                        fontWeight: '400',
                                                        color: '#202224'
                                                    }}>
                                                    {row.profession_name}
                                                </Typography>
                                            </TableCell>

                                            <TableCell>
                                                <Typography
                                                    style={{
                                                        fontSize: '14px',
                                                        fontWeight: '400',
                                                        color: '#202224'
                                                    }}>
                                                    {row.name}
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography
                                                    style={{
                                                        fontSize: '14px',
                                                        fontWeight: '400',
                                                        color: '#202224'
                                                    }}>
                                                    {row.first_name + " " + row.last_name}
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography
                                                    style={{
                                                        fontSize: '14px',
                                                        fontWeight: '400',
                                                        color: '#202224'
                                                    }}>
                                                    {row.email}
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography
                                                    style={{
                                                        fontSize: '14px',
                                                        fontWeight: '400',
                                                        color: '#202224'
                                                    }}>
                                                    {row.phone_number}
                                                </Typography>
                                            </TableCell>

                                            <TableCell>
                                                <Button
                                                    variant="outlined"
                                                    disableRipple
                                                    style={{
                                                        borderRadius: "10px",
                                                        border: row.status === "Approved" ? "1px solid #00C3B8" : "1px solid #F29006",
                                                        height: '30px',
                                                        backgroundColor: row.status === "Approved" ? '#00C3B8' : "#F29006", height: '30px',
                                                        height: "30px",
                                                        margin: "5px",
                                                        color: "white",
                                                        cursor: "default",
                                                        textTransform: "none",
                                                    }}
                                                >
                                                    {row.status}
                                                </Button>
                                                {/* <div style={{
                                    backgroundColor: row.status === "Approved" ? '#00C3B8' : "#F29006", height: '30px',
                                    borderRadius: '13px'
                                }}>
                                    <Typography
                                        style={{
                                            fontSize: '14px',
                                            fontWeight: '400',
                                            color: "white",
                                            padding: '5px',
                                            marginLeft: '5px'
                                        }}>
                                        {row.status}
                                    </Typography>
                                </div> */}
                                            </TableCell>
                                        </TableRow>
                                    )
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </Paper>
            {/* <div style={{
                display: 'flex',
                justifyContent: 'space-between', padding: '10px'
            }}>
                <Typography
                    style={{
                        fontSize: '14px'
                    }}>
                    Showing 1-09 of 78
                </Typography>
                <Box
                    sx={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        border: '1px solid',
                        borderColor: 'divider',
                        borderRadius: 2,
                        bgcolor: 'background.paper',
                        color: 'text.secondary',
                        '& svg': {
                            m: 1,
                        },
                    }}
                >
                    <ArrowBackIosIcon style={{ height: '15px', width: '15px' }} />
                    <Divider orientation="vertical" variant="middle" flexItem />
                    <ArrowForwardIosIcon style={{ height: '15px', width: '15px' }} />
                </Box>
            </div> */}
        </div>
        // </Grid>
    );
}
