import React, { useEffect, useState } from "react";

const RedirectPage = () => {
  const [showFallback, setShowFallback] = useState(false);

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    let redirectURL = "";

    if (/android/i.test(userAgent)) {
      redirectURL = "market://details?id=com.medical.circles.prod.release";

      setTimeout(() => {
        setShowFallback(true);
        window.location.href = "https://play.google.com/store/apps/details?id=com.medical.circles.prod.release";
      }, 3000);

    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      redirectURL = "itms-apps://apps.apple.com/us/app/medical-circles/id6740196927";

      setTimeout(() => {
        setShowFallback(true);
        window.location.href = "https://apps.apple.com/us/app/medical-circles/id6740196927";
      }, 3000);

    } else {
      alert("Unsupported device");
      return;
    }

    window.location.href = redirectURL;
  }, []);

  return (
    <div style={{ textAlign: "center", marginTop: "20px" }}>
      <p>Redirecting to the app store...</p>
      {showFallback && (
        <p>
          If you are not redirected, click{" "}
          { /android/i.test(navigator.userAgent) ? (
            <a
              href="https://play.google.com/store/apps/details?id=com.medical.circles.prod.release"
              target="_blank"
              rel="noopener noreferrer"
            >
              here
            </a>
          ) : (
            <a
              href="https://apps.apple.com/us/app/medical-circles/id6740196927"
              target="_blank"
              rel="noopener noreferrer"
            >
              here
            </a>
          )}{" "}
          to download the app.
        </p>
      )}
    </div>
  );
};

export default RedirectPage;
