import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
    Button,
    Grid,
    Paper,
    TableCell,
    TableRow,
    TextField,
    Typography,
    Collapse,
} from "@mui/material";
import React, { useEffect, useState, useCallback } from "react";
import Datatables from "./DataTablesProfession";
import useCancelRequest from "../../../../hooks/useCancelToken";
import IconButton from "@mui/material/IconButton";

import { Box, Switch } from "@material-ui/core";
// import Collapse from '@mui/material/Collapse';

import SearchIcon from "@mui/icons-material/Search";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import SubSpeciality from "./subSpeciality";
import SubRank from "./SubRank";
import clsx from "clsx";
import moment from "moment";
import { useSnackbar } from "notistack";
import { Link } from "react-router-dom";
import useGeneralStyle from "../../../../assets/css/general";
import { apiClient } from "../../../../config/apiClient";
import useCallbackStatus from "../../../../hooks/useCallbackStatus";
import { useAuth } from "../../../../providers/AuthProvider";
import CreateModal from "./CreateSubRank";
import SubSpecialityCreateModal from "./CreateSubSpeciality";
import CreateRankModal from "./CreateSubRank";

const tableHeader = [
    { id: "Specialities", align: "left", label: "RANK", minWidth: 170 },
    { id: "created", align: "left", label: "CREATED AT", minWidth: 170 },
    { id: "Specialities", align: "left", label: "CREATED BY", minWidth: 170 },
    { id: "ACTION", align: "left", label: "ACTION", minWidth: 170 },
    //   { id: "ACTION", align: "left", label: "RANKS", minWidth: 170 },
];

let diffTableHeader = [...tableHeader];
diffTableHeader.splice(4, 1);

const useStyles = makeStyles((theme2) => ({
    formControl: {
        margin: theme2.spacing(1),
        minWidth: 160,
        color: "white",
        borderBottom: "none",
    },
    selectEmpty: {
        marginTop: theme2.spacing(2),
    },
    icon: {
        fill: "white",
    },
    root: {
        width: "100%",
        overflowX: "auto",
        // marginTop: '25px',
    },
    suspendedStatus: {
        borderRadius: "5px",
        padding: "5px 30px",
        border: "1px solid #fd6868",
        backgroundColor: "#ffc3c3",
        color: "#e64848",
        fontWeight: "bold",
    },
    rejectedStatus: {
        borderRadius: "5px",
        padding: "5px 30px",
        border: "1px solid #fd6868",
        backgroundColor: "#ffc3c3",
        color: "#e64848",
        fontWeight: "bold",
    },
    pendingStatus: {
        borderRadius: "5px",
        padding: "5px 30px",
        border: "1px solid #eab20a",
        backgroundColor: "#ffeeb5",
        color: "#eab20a",
        fontWeight: "bold",
    },
    registeredStatus: {
        borderRadius: "5px",
        padding: "5px 30px",
        border: "1px solid #6ac358",
        backgroundColor: "#c3f4ba",
        color: "#6ac358",
        fontWeight: "bold",
    },
    container: {
        height: "440px",
        width: "100%",
    },
    activeFilter: {
        // backgroundColor: theme2.palette.primary.light,
        borderBottom: "2px solid white ",
        borderRadius: "0px",
        color: "white",
        fontWeight: 600,
    },
    icon: {
        fill: "#FFFFFF",
    },
    carddelModal: {
        width: "500px",
        borderRadius: "12px",
        backgroundColor: "#1b1f3b",
        padding: "1rem",
        position: "relative",

        [theme2.breakpoints.down("sm")]: {
            width: "90vw !important",
        },

        "&:focus-visible": {
            outline: "none",
        },
    },
    profileData: {
        color: "white",
        paddingTop: "2px",
        fontSize: "16px",
        // fontWeight: 600,
    },
    profileHeading: {
        fontSize: "12px",
        // fontWeight: 600,
        color: "#B0B2B6",
        textAlign: "left",
    },

    parentofappointmentcontent: {
        [theme2.breakpoints.down("md")]: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
        },
    },
    bottombox: {
        [theme2.breakpoints.down("xs")]: {
            marginLeft: "0 !important",
            display: "flex",
            justifyContent: "flex-start !important",
            alignItems: "flex-start !important",
        },
    },
    doublerarrow: {
        [theme2.breakpoints.down("xs")]: {
            transform: "rotate(90deg)",
            margin: "1rem !important",
        },
    },

    tabsContainer: {
        backgroundColor: "#fff",
        margin: "20px 20px 0px 20px",
        height: "44px",
        [theme2.breakpoints.down("md")]: {
            width: "95%",
            overflow: "scroll",
        },
        [theme2.breakpoints.down("sm")]: {
            width: "88%",
            overflow: "scroll",
        },
        "&::-webkit-scrollbar": {
            height: "0",
        },
    },
}));
const Specialities = ({ professionData }) => {
    const globalClasses = useGeneralStyle();
    const classes = useStyles();

    const ToggleSwitch = withStyles((theme) => ({
        root: {
            width: 36,
            height: 20,
            padding: 0,
            margin: theme.spacing(1),
        },
        switchBase: {
            padding: 1,
            "&$checked": {
                transform: "translateX(16px)",
                color: theme.palette.common.white,
                "& + $track": {
                    backgroundColor: "#4379EE",
                    opacity: 1,
                    border: "none",
                },
            },
        },
        thumb: {
            width: 18,
            height: 18,
        },
        track: {
            borderRadius: "50px",
            border: `1px solid ${theme.palette.grey[400]}`,
            backgroundColor: "#7E7E7E",
            opacity: 1,
            transition: theme.transitions.create(["background-color", "border"]),
        },
        checked: {},
        focusVisible: {},
    }))(({ classes, ...props }) => {
        return (
            <Switch
                focusVisibleClassName={classes.focusVisible}
                disableRipple
                classes={{
                    root: classes.root,
                    switchBase: classes.switchBase,
                    thumb: classes.thumb,
                    track: classes.track,
                    checked: classes.checked,
                }}
                {...props}
            />
        );
    });

    // ==================== API LOGIC ======================

    const [initialData, setData] = useState([]);
    const [totalPages, setTotalPages] = useState(0);

    const [speciality, setSpeciality] = useState("");
    const [refresh, setRefresh] = useState(false)
    const [profession, setProfession] = useState("");
    const [openCreateSub, setOpenCreateSub] = useState(false);
    const [openCreateRank, setOpenCreateRank] = useState(false);

    const [speciality_id, setSpecialityId] = useState(null);
    const [rank_id, setRankId] = useState(null);

    const [search, setSearchKey] = useState("");
    const [subName, setSubName] = useState();
    const [rankName, setRankName] = useState();

    const [openSpeciality, setOpenSpeciality] = useState(false);
    const [openRank, setOpenRank] = useState(false);

    const [create, setCreate] = useState(false);
    const [showMore, setShowMore] = useState(false);
    const notification = useSnackbar();
    const [specialityData, setSpecialityData] = useState([]);
    const [hasMore, setHasMore] = useState(false);
    const auth = useAuth();
    const apiSource = useCancelRequest();
    const [currentRow, setCurrentRow] = useState(null);
    const [editModal, setEditModal] = useState(false);
    const [modal, setModal] = React.useState(false);
    const createProfessionApiStatus = useCallbackStatus();
    const editSpecializationApiStatus = useCallbackStatus();
    const getSpecializationListApiStatus = useCallbackStatus();
    const manageSpecialityApiStatus = useCallbackStatus();
    const [showSpeciality, setShowSpeciality] = useState(false);

    const [editedRow, setEditedRow] = useState({});

    const getSpecializationList = async (refresh) => {
        let apiBody = {};
        if (search) {
            apiBody.search_string = search;
            apiBody.search_fields = ["name"];
        }
        await getSpecializationListApiStatus.run(
            apiClient("POST", "common", "manageentities", {
                body: {
                    action: "list",
                    entity_type: "rank",
                    profession_id: professionData.id,
                    ...apiBody,
                },
                shouldUseDefaultToken: false,
                cancelToken: apiSource.token,
                enableLogging: true,
            })
                .then((result) => {
                    const {
                        content: { data, has_more, next_token },
                    } = result;
                    console.log("data from api specialty", data);

                    var mainData = data.filter(item => item.profession_id && (item.profession_id !== null));

                    if (refresh) setSpecialityData([...mainData]);
                    else {
                        setSpecialityData([...specialityData, ...mainData]);
                    }
                    // setSpecialityData(data);
                    setHasMore(has_more);
                    setTotalPages(next_token);
                })
                .catch((err) => {
                    if (err && err.code === 403) {
                        auth.logout();
                    } else {
                        notification.enqueueSnackbar(err.message, {
                            variant: "err",
                            autoHideDuration: 2000,
                        });
                    }
                })
        );
    };

    const manageSpeciality = async (row) => {
        let apiBody = {
            action: "update",
            entity_type: "rank",
            entity_id: row.id,
            profession_id: professionData.id,
        };
        apiBody.is_enabled = !row.is_enabled;
        console.log(apiBody, "enable edit");
        await manageSpecialityApiStatus.run(
            apiClient("POST", "common", "manageentities", {
                body: {
                    ...apiBody,
                },
                shouldUseDefaultToken: false,
                cancelToken: apiSource.token,
                enableLogging: true,
            })
                .then((result) => {
                    getSpecializationList(true);
                    const {
                        content: { data, has_more, next_token },
                    } = result;
                    console.log("data from api", data);
                    // setSpecialityData(data);
                    // setHasMore(has_more);
                    // setTotalPages(next_token);
                    notification.enqueueSnackbar("Updated Successfully", {
                        variant: "err",
                        autoHideDuration: 2000,
                    });
                })
                .catch((err) => {
                    if (err && err.code === 403) {
                        auth.logout();
                    } else {
                        notification.enqueueSnackbar(err.message, {
                            variant: "err",
                            autoHideDuration: 2000,
                        });
                    }
                })
        );
    };
    const createProfession = async (action) => {
        let apiBody = {
            action: action,
            entity_type: "rank",
            entity_name: profession,
            profession_id: professionData.id,
        };
        if (action === "update") {
            apiBody.entity_id = currentRow.id;
        }

        await createProfessionApiStatus.run(
            apiClient("POST", "common", "manageentities", {
                body: { ...apiBody },
                shouldUseDefaultToken: false,
                cancelToken: apiSource.token,
                enableLogging: true,
            })
                .then((result) => {
                    const {
                        content: { data, has_more, next_token },
                    } = result;
                    console.log(result);
                    setCreate(false);
                    setProfession(null);
                    setCurrentRow(null);
                    getSpecializationList(true);
                    notification.enqueueSnackbar("Successfully Updated", {
                        variant: "success",
                        autoHideDuration: 2000,
                    });
                })
                .catch((err) => {
                    if (err && err.code === 403) {
                        auth.logout();
                    } else {
                        notification.enqueueSnackbar(err.message, {
                            variant: "err",
                            autoHideDuration: 2000,
                        });
                    }
                })
        );
    };
    const createSubSpecialty = async (action) => {
        let apiBody = {
            action: action,
            entity_type: "sub_specialty",
            entity_name: subName,
            specialty_id: speciality_id,
        };
        if (action === "update") {
            apiBody.entity_id = currentRow.id;
        }
        console.log("sub");
        console.log(apiBody);
        await createProfessionApiStatus.run(
            apiClient("POST", "common", "manageentities", {
                body: { ...apiBody },
                shouldUseDefaultToken: false,
                cancelToken: apiSource.token,
                enableLogging: true,
            })
                .then((result) => {
                    const {
                        content: { data, has_more, next_token },
                    } = result;
                    console.log(result);
                    setRefresh(true)
                    setOpenCreateSub(false);
                    setCurrentRow(null);
                    getSpecializationList(true);
                    notification.enqueueSnackbar("Successfully Updated", {
                        variant: "success",
                        autoHideDuration: 2000,
                    });
                })
                .catch((err) => {
                    if (err && err.code === 403) {
                        auth.logout();
                    } else {
                        notification.enqueueSnackbar(err.message, {
                            variant: "error",
                            autoHideDuration: 2000,
                        });
                    }
                })
        );
    };
    const createRank = async (action) => {
        let apiBody = {
            action: action,
            entity_type: "rank",
            entity_name: rankName,
            specialty_id: speciality_id,
        };
        if (action === "update") {
            apiBody.entity_id = currentRow.id;
        }
        console.log("rank");
        console.log(apiBody);
        await createProfessionApiStatus.run(
            apiClient("POST", "common", "manageentities", {
                body: { ...apiBody },
                shouldUseDefaultToken: false,
                cancelToken: apiSource.token,
                enableLogging: true,
            })
                .then((result) => {
                    const {
                        content: { data, has_more, next_token },
                    } = result;
                    setRefresh(true)
                    console.log(result);
                    setOpenCreateRank(false);
                    setCurrentRow(null);
                    getSpecializationList(true);
                    notification.enqueueSnackbar("Successfully Updated", {
                        variant: "success",
                        autoHideDuration: 2000,
                    });
                })
                .catch((err) => {
                    if (err && err.code === 403) {
                        auth.logout();
                    } else {
                        notification.enqueueSnackbar(err.message, {
                            variant: "error",
                            autoHideDuration: 2000,
                        });
                    }
                })
        );
    };

    const toggleSpeciality = useCallback((id) => {
        setOpenSpeciality((state) => ({
            ...state,
            [id]: !Boolean(state[id]),
        }));
    }, []);
    const toggleRank = useCallback((id) => {
        setOpenRank((state) => ({
            ...state,
            [id]: !Boolean(state[id]),
        }));
    }, []);

    useEffect(() => {
        getSpecializationList(true);
    }, []);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            getSpecializationList(true);
        }, 300);

        return () => clearTimeout(delayDebounceFn);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    return (
        <>
            {create ? (
                <CreateModal
                    setCreate={setCreate}
                    setProfession={setProfession}
                    profession={profession}
                    createProfession={createProfession}
                    currentRow={currentRow}
                    setCurrentRow={setCurrentRow}
                    apiStatus={createProfessionApiStatus}
                />
            ) : null}
            {openCreateRank ? (
                <CreateRankModal
                    setCreate={setOpenCreateRank}
                    setProfession={setRankName}
                    profession={rankName}
                    createProfession={createRank}
                    currentRow={currentRow}
                    apiStatus={createProfessionApiStatus}
                    setCurrentRow={setCurrentRow}
                />
            ) : null}
            {openCreateSub ? (
                <SubSpecialityCreateModal
                    setCreate={setOpenCreateSub}
                    setProfession={setSubName}
                    profession={subName}
                    setRefresh={setRefresh}
                    createProfession={createSubSpecialty}
                    currentRow={currentRow}
                    apiStatus={createProfessionApiStatus}
                    setCurrentRow={setCurrentRow}
                />
            ) : null}

            <Grid container>
                <Grid
                    container
                    style={{
                        backgroundColor: "white",
                        display: "flex",
                        justifyContent: "space-between",
                        paddingLeft: "20px",
                    }}
                    className="ChangeRequestsContainer"
                >
                    <Grid
                        style={{
                            display: "flex",
                            alignItems: "center",
                            padding: "10px 0px",
                        }}
                        item
                        xs={11}
                        sm={5}
                        md={5}
                        lg={5}
                    >
                        <TextField
                            onChange={(e) => setSearchKey(e.target.value)}
                            variant="outlined"
                            style={{ backgroundColor: "#F5F5F7", width: "400px" }}
                            inputProps={{
                                style: { padding: "12px" },
                            }}
                            InputProps={{
                                startAdornment: (
                                    <SearchIcon
                                        variant="filled"
                                        fontSize="small"
                                        style={{ backgroundColor: "#F5F5F7", color: "#141522" }}
                                    />
                                ),
                                className: "inputFont",
                            }}
                            placeholder="Search in ranks"
                        />
                    </Grid>
                    <Grid
                        style={{
                            display: "flex",
                            alignItems: "right",
                            padding: "10px 0px",
                        }}
                        item
                        xs={11}
                        sm={2}
                        md={2}
                        lg={2}
                    >
                        <Button
                            variant="outlined"
                            disableElevation
                            style={{ color: "white", backgroundColor: "#4379EE" }}
                        >
                            <Typography
                                style={{ color: "white", cursor: "pointer" }}
                                onClick={() => {
                                    setProfession("");
                                    setCreate(true);
                                }}
                            >
                                Create Rank
                            </Typography>
                        </Button>
                    </Grid>
                </Grid>
                <Grid item xs={12} style={{ paddingTop: "20px" }}>
                    <Paper className={classes.root}>
                        <Datatables
                            data={specialityData}
                            loading={getSpecializationListApiStatus.isPending}
                            getDataList={getSpecializationList}
                            hasMore={hasMore}
                            nextToken={totalPages}
                            tableHeader={tableHeader}
                            orderByKey="name"
                            isEmpty={specialityData.length === 0}
                        >
                            {specialityData.map((row, index) => {
                                const open = Boolean(openSpeciality[row.id]);
                                const open2 = Boolean(openRank[row.id]);

                                return (
                                    <>
                                        {/* <TableRow > */}
                                        <TableRow
                                            key={index}
                                            hover
                                            sx={{ "& > *": { borderBottom: "unset" } }}
                                        >
                                            <TableCell>
                                                <Typography>{row.name}</Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography>
                                                    {/* {moment
                            .unix(row.created_at)
                            .format("Do MMM YYYY hh:ss A")} */}
                                                    {moment.unix(row.created_at).format("Do MMM YYYY")}
                                                </Typography>
                                            </TableCell>

                                            <TableCell>{row.created_by_name || "-"}</TableCell>
                                            <TableCell style={{ display: "flex" }}>
                                                <ToggleSwitch
                                                    checked={row.is_enabled}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        manageSpeciality(row);
                                                    }}
                                                />
                                                {row.is_enabled ? (
                                                    <>
                                                        <Box style={{ padding: "9px" }}> | </Box>
                                                        <Link
                                                            className={clsx(
                                                                {
                                                                    [classes.disabledLink]: row.is_enabled === 0,
                                                                },
                                                                globalClasses.link
                                                            )}
                                                            style={{ padding: "9px", color: '#4379EE' }}
                                                            onClick={() => {
                                                                setCurrentRow(row);
                                                                setProfession(row.name);
                                                                setCreate(true);
                                                            }}
                                                        >
                                                            <u>Edit</u>
                                                        </Link>
                                                        {/* <Box style={{ padding: "9px" }}> | </Box>
                            <Link
                              className={clsx(
                                {
                                  [classes.disabledLink]: row.is_enabled === 0,
                                },
                                globalClasses.link
                              )}
                              style={{ padding: "9px", color: '#4379EE' }}
                              onClick={() => {
                                setSubName("");
                                setOpenCreateSub(true);
                                setSpecialityId(row.id);

                                //   setCurrentRow(row);
                                //   setProfession(row.name);
                                //   setCreate(true);
                              }}
                            >
                              <u>Create Sub-Speciality</u>
                            </Link> */}
                                                        {/* <Box style={{ padding: "9px" }}> | </Box>
                            <Link
                              className={clsx(
                                {
                                  [classes.disabledLink]: row.is_enabled === 0,
                                },
                                globalClasses.link
                              )}
                              style={{ padding: "9px", color: '#4379EE' }}
                              onClick={() => {
                                setRankName("");
                                setSpecialityId(row.id);

                                setOpenCreateRank(true);

                                //   setCurrentRow(row);
                                //   setProfession(row.name);
                                //   setCreate(true);
                              }}
                            >
                              <u>Create Rank</u>
                            </Link> */}
                                                    </>
                                                ) : null}
                                            </TableCell>
                                            {/* <TableCell>
                        <IconButton
                          aria-label="expand row"
                          size="small"
                          onClick={() => {
                            setSpecialityId(row.id);
                            toggleSpeciality(row.id);
                          }}
                        >
                          {open ? (
                            <KeyboardArrowUpIcon />
                          ) : (
                            <KeyboardArrowDownIcon />
                          )}
                        </IconButton>
                      </TableCell>
                      <TableCell>
                        <IconButton
                          aria-label="expand row"
                          size="small"
                          onClick={() => {
                            setSpecialityId(row.id);
                            toggleRank(row.id);
                          }}
                        >
                          {open2 ? (
                            <KeyboardArrowUpIcon />
                          ) : (
                            <KeyboardArrowDownIcon />
                          )}
                        </IconButton>
                      </TableCell> */}
                                        </TableRow>
                                        <TableRow key={index}>
                                            <TableCell
                                                style={{ paddingBottom: 0, paddingTop: 0 }}
                                                colSpan={6}
                                            >
                                                <Collapse in={open} timeout="auto" unmountOnExit>
                                                    <Box sx={{ margin: 1, marginBottom: "10px" }}>
                                                        <SubSpeciality specialityId={speciality_id} refs={refresh} setRefresh={setRefresh} />
                                                    </Box>
                                                </Collapse>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow key={index}>
                                            <TableCell
                                                style={{ paddingBottom: 0, paddingTop: 0 }}
                                                colSpan={6}
                                            >
                                                <Collapse in={open2} timeout="auto" unmountOnExit>
                                                    <Box sx={{ margin: 1, marginBottom: "10px" }}>
                                                        <SubRank specialityId={speciality_id} refs={refresh} setRefresh={setRefresh} />
                                                    </Box>
                                                </Collapse>
                                            </TableCell>
                                        </TableRow>
                                    </>
                                );
                            })}
                        </Datatables>
                    </Paper>
                </Grid>
            </Grid>
        </>
    );
};

export default Specialities;
