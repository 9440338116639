import activeCompany from "../../assets/LeftSideBarIcons/activeCompany.png";
import activeManagement from "../../assets/LeftSideBarIcons/activeManagement.png";
import activeProfession from "../../assets/LeftSideBarIcons/activeProfession.png";
import activeProfile from "../../assets/LeftSideBarIcons/activeProfile.png";
import activeTicket from "../../assets/LeftSideBarIcons/activeTicket.png";
import category from "../../assets/LeftSideBarIcons/category.svg";
import company from "../../assets/LeftSideBarIcons/company.png";
import logout from "../../assets/LeftSideBarIcons/logout.png";
import management from "../../assets/LeftSideBarIcons/management.png";
import profession from "../../assets/LeftSideBarIcons/profession.png";
import profile from "../../assets/LeftSideBarIcons/profile.png";
import ticket from "../../assets/LeftSideBarIcons/ticket.png";
// import profile from "../../assets/LeftSideBarIcons/profile.png";
// import activeProfile from "../../assets/LeftSideBarIcons/activeProfile.png";
// import management from "../../assets/LeftSideBarIcons/management.png";
// import activeManagement from "../../assets/LeftSideBarIcons/activeManagement.png";
import activeDashboard from "../../assets/LeftSideBarIcons/activeDashboard.png";
import dashboard from "../../assets/LeftSideBarIcons/dashboard.png";
import activeNetwork from "../../assets/LeftSideBarIcons/activeNetwork.png";
import network from "../../assets/LeftSideBarIcons/network.png";
import admin from "../../assets/LeftSideBarIcons/admin.png";
import analytics from "../../assets/LeftSideBarIcons/analytics.png";
import broadcast from "../../assets/LeftSideBarIcons/broadcast.png";
import billing from "../../assets/LeftSideBarIcons/billing.png";
import setting from "../../assets/LeftSideBarIcons/setting.png";
import BroadcastActive from "../../assets/LeftSideBarIcons/broadcastActive.png"
import AdminActive from "../../assets/LeftSideBarIcons/activeAdmin.png"
import ProfileIcon from "../../assets/LeftSideBarIcons/iconoir_profile-circle.png"
import IncomingIcon from "../../assets/LeftSideBarIcons/streamline_mail-incoming.png"

import _ from "lodash";


const hasAccess = (page) => {
    let activePages = [];
    activePages = JSON.parse(localStorage.getItem("activePages"));

    if (!_.isNull(activePages) && (activePages.includes(page) || page === "Yes")) {
        return true;
    } else return false;
};

const menu = [
    {
        text: "Overview", //Name of List Item
        hasAccess: hasAccess("Dashboard"),
        textCompare: "Dashboard",
        icon: dashboard, //Icon of List Item
        activeIcon: activeDashboard, //Icon of List Item
        rootpath: "overview", //RootPath used for selected view
        url: "/overview", //After Click where you want to redirect
        active: false,
        child: [],
    },
    {
        text: "Access Management", //Name of List Item
        // hasAccess: hasAccess("Access Management"),
        hasAccess: true,
        textCompare: "Access Management",
        icon: admin, //Icon of List Item
        activeIcon: AdminActive, //Icon of List Item
        rootpath: "access", //RootPath used for selected view
        url: "/access", //After Click where you want to redirect
        active: false,
        child: [
            {
                text: "Admin Management",
                textCompare: "Admin Management",
                icon: null,
                rootpath: "/access/admins",
                url: "/access/admins",
                active: false,
            },
            {
                text: "User Management",
                textCompare: "User Management",
                icon: null,
                rootpath: "/access/users",
                url: "/access/users",
                active: false,
            },
            {
                text: "Role Access",
                textCompare: "Role Access",
                hasAccess: hasAccess("Role Access"),
                // icon: ticket,
                // activeIcon: activeTicket,
                rootpath: "/access/role_access",
                url: "/access/role_access",
                active: false,
            }
        ],
    },

    {
        text: "Incoming Enquiries",
        textCompare: "Incoming Enquiries",
        hasAccess: true,
        icon: IncomingIcon,
        activeIcon: IncomingIcon,
        rootpath: "enquiries",
        url: "/enquiries",
        active: false,
        child: [
            {
                text: "Support Tickets",
                textCompare: "Support Tickets",
                // icon: ticket,
                hasAccess: hasAccess("Support Tickets"),
                // activeIcon: activeTicket,
                rootpath: "/enquiries/support_tickets",
                url: "/enquiries/support_tickets",
                active: false,
                // child: [],
            },
            {
                text: "User Requests",
                textCompare: "User Requests",
                hasAccess: hasAccess("User Requests"),
                // icon: ticket,
                // activeIcon: activeTicket,
                rootpath: "/enquiries/user_requests",
                url: "/enquiries/user_requests",
                active: false,
                // child: [],
            },
            {
                text: "Leads",
                textCompare: "Leads",
                // icon: ticket,
                hasAccess: hasAccess("Support Tickets"),
                // activeIcon: activeTicket,
                rootpath: "/enquiries/query",
                url: "/enquiries/query",
                active: false,
                // child: [],
            }
        ],
    },
    {
        text: "Broadcasts",
        textCompare: "Broadcasts",
        hasAccess: true,
        icon: broadcast,
        activeIcon: BroadcastActive,
        rootpath: "broadcast",
        url: "/broadcast",
        active: false,
        child: [
            {
                text: "Announcements",
                textCompare: "Announcements",
                hasAccess: true,
                // icon: speaker,
                // activeIcon: activeSpeaker,
                rootpath: "/broadcast/announcements",
                url: "/broadcast/announcements",
                active: false,
                // child: [],
            }
        ],
    },
    // {
    //     text: "Role Access",
    //     textCompare: "Role Access",
    //     hasAccess: hasAccess("Role Access"),
    //     icon: ticket,
    //     activeIcon: activeTicket,
    //     rootpath: "role_access",
    //     url: "/role_access",
    //     active: false,
    //     child: [],
    // },
    // {
    //     text: "Support Tickets",
    //     textCompare: "Support Tickets",
    //     icon: ticket,
    //     hasAccess: hasAccess("Support Tickets"),
    //     activeIcon: activeTicket,
    //     rootpath: "support_tickets",
    //     url: "/support_tickets",
    //     active: false,
    //     child: [],
    // },
    // {
    //     text: "Query",
    //     textCompare: "Query",
    //     icon: ticket,
    //     hasAccess: hasAccess("Support Tickets"),
    //     activeIcon: activeTicket,
    //     rootpath: "query",
    //     url: "/query",
    //     active: false,
    //     child: [],
    // },
    // {
    //   text: "Groups",
    //   textCompare: "Groups",
    //   icon: ticket,
    //   activeIcon: activeTicket,
    //   rootpath: "groups",
    //   url: "/groups",
    //   active: false,
    //   child: [],
    // },
    // {
    //     text: "Announcements",
    //     textCompare: "Announcements",
    //     hasAccess: hasAccess("Announcements"),
    //     icon: speaker,
    //     activeIcon: activeSpeaker,
    //     rootpath: "announcements",
    //     url: "/announcements",
    //     active: false,
    //     child: [],
    // },
    {
        text: "Settings",
        textCompare: "settings",
        hasAccess: hasAccess("Profession settings"),
        icon: setting,
        activeIcon: setting,
        rootpath: "settings",
        url: "/settings",
        active: false,
        child: [
            {
                text: "Profession Settings",
                textCompare: "Profession settings",
                hasAccess: hasAccess("Profession settings"),
                // icon: profession,
                // activeIcon: activeProfession,
                rootpath: "/settings/profession",
                url: "/settings/profession",
                active: false,
                // child: [],
            }
        ],
    },
    // {
    //     text: "Company Info",
    //     textCompare: "Compan Info",
    //     hasAccess: hasAccess("Compan Info"),
    //     icon: company,
    //     activeIcon: activeCompany,
    //     rootpath: "company",
    //     url: "/company",
    //     active: false,
    //     child: [
    //         // {
    //         //   text: "Contact Us",
    //         //   textCompare: "Contact Us",
    //         //   icon: null,x
    //         //   rootpath: "company/contact",
    //         //   active: false,
    //         //   url: "/company/contact",
    //         // },
    //         // {
    //         //   text: "About Us",
    //         //   textCompare: "About Us",
    //         //   icon: null,
    //         //   rootpath: "company/about",
    //         //   active: false,
    //         //   url: "/company/about",
    //         // },
    //         {
    //             text: "Privacy Policy",
    //             textCompare: "Privacy Policy",
    //             icon: null,
    //             rootpath: "company/privacy-policy",
    //             active: false,
    //             url: "/company/privacy-policy",
    //         },
    //         {
    //             text: "Terms of Use",
    //             textCompare: "Terms of Use",
    //             icon: null,
    //             rootpath: "company/termsofuse",
    //             active: false,
    //             url: "/company/termsofuse",
    //         },
    //     ],
    // },
    // {
    //   text: "Operation",
    //   textCompare: "Operation",
    //   icon: mesasge,
    //   rootpath: "operation",
    //   url: "/operation",
    //   active: false,
    //   child: [],
    // },
    // {
    //   text: "Accessibility",
    //   textCompare: "Accessibility",
    //   icon: accessibility,
    //   rootpath: "accessibility",
    //   url: "/accessibility",
    //   active: false,
    //   child: [],
    // },

    {
        text: "Profile",
        textCompare: "Profile",
        hasAccess: hasAccess("Yes"),
        icon: ProfileIcon,
        activeIcon: ProfileIcon,
        rootpath: "profile",
        url: "/profile",
        active: false,
        child: [],
    },
    // {
    //     text: "Logout",
    //     textCompare: "Logout",
    //     hasAccess: hasAccess("Yes"),
    //     icon: logout,
    //     activeIcon: logout,
    //     rootpath: "logout",
    //     url: "/logout",
    //     active: false,
    //     child: [],
    // },
];
const menu2 = [
    // {
    //     text: "Settings",
    //     textCompare: "Settings",
    //     hasAccess: hasAccess("User Requests"),
    //     icon: setting,
    //     activeIcon: activeTicket,
    //     rootpath: "settings",
    //     // url: "/settings",
    //     active: false,
    //     child: [],
    // },
    {
        text: "Logout", //Name of List Item
        hasAccess: hasAccess("User Requests"),
        textCompare: "Logout",
        icon: logout, //Icon of List Item
        activeIcon: logout, //Icon of List Item
        rootpath: "logout", //RootPath used for selected view
        url: "/logout", //After Click where you want to redirect
        active: false,
        child: [],
    }
];
export { menu, menu2 };

